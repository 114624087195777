import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"


const MenuPage = () => (
  <Layout >
    <div className="flex justify-center">

    <Link to="/">
    <StaticImage
            src="../images/menu.png"
            loading="eager"
            width={720}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="menu"
            />
      <br />
      Go back to the homepage</Link>
            </div>
  </Layout>
)

export const Head = () => <Seo title="Menu" />

export default MenuPage
